$('.accordion').on('show.bs.collapse', function(e) {
    $(e.target).prev('.panel-heading').addClass('active');
  }).on('hide.bs.collapse', function(e) {
    $(e.target).prev('.panel-heading').removeClass('active');
  });

(function() {
    String.prototype.toCapitalizeWords = String_toCapitalizeWords;
    function String_toCapitalizeWords() {
		var capitalized = this.toLowerCase();
		var prevChar = ' ';
		for( var i=0;i<capitalized.length;i++ ) {
			if( prevChar == ' ' || prevChar == "\'" || prevChar == ".") {
			capitalized = capitalized.substr(0,i)+capitalized.charAt(i).toUpperCase()+capitalized.substr(i+1);
			}
			prevChar = capitalized.charAt(i);
		}
		return capitalized;
	}
})();
