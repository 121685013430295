import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './js/sito/bootstrapValidator.js';
import './js/sito/utils.js';

import './css/font-awesome.css';
import './css/website.style.css';
